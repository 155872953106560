import { FC, SyntheticEvent, useCallback, useContext } from "react";
import { FavoriteEventsContext, useRuntimeConfig } from "@finbackoffice/site-core";
import classnames from "classnames";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Outcome from "components/pages/sport/base/outcome/Outcome";
import { Svg } from "components/base/svg/Svg";
import HomeTournamentGameScoreboard from "../../scoreboard/HomeTournamentGameScoreboard";
import { HomeTournamentGameItemContext } from "../../HomeTournamentGameItem";
import styles from "./top.module.sass";

const Top: FC = () => {
    const {
        game,
        mainMarketId,
        mainOdds,
        mainMarketStatus,
        onOutcomeClick,
        columnCount,
        isInternationalCategory,
    } = useContext(HomeTournamentGameItemContext);
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { addFav, removeFav, isFav } = useContext(FavoriteEventsContext);

    const handleFavClick = useCallback(
        (event: SyntheticEvent) => {
            event.preventDefault();
            event.stopPropagation();
            if (isFav(game.id)) {
                removeFav(game.id);
            } else {
                addFav({
                    id: game.id,
                    timestamp: new Date(game.scheduled_at!).valueOf(),
                    sport_id: game.sport_id,
                    tournament_id: game.tournament_id,
                });
            }
        },
        [addFav, game.id, game.scheduled_at, game.sport_id, game.tournament_id, isFav, removeFav],
    );

    return (
        <FadeInAnimation>
            <div>
                <div
                    className={styles.topGameItem}
                    style={{
                        backgroundImage: `url(${ASSETS_URL}/common/home-scoreboards/${isInternationalCategory ? "international-soccer" : game.sport_id}.webp)`,
                    }}>
                    {game.tournament_is_top && (
                        <Svg
                            src="/common/desktop/base-icons/top-leagues.svg"
                            wrapper="span"
                            className={styles.flagTop}
                        />
                    )}

                    <i
                        className={classnames(styles.unstarred, isFav(game.id) && styles.starred)}
                        onClick={handleFavClick}
                    />
                    <HomeTournamentGameScoreboard
                        game={game}
                        // layout="top"
                    />
                    {mainMarketId && mainOdds && (
                        <div className={styles.homeGameItemMarket}>
                            <Outcome
                                outcome={mainOdds.homeOutcome}
                                marketStatus={mainMarketStatus}
                                variant="mainMarket"
                                onOutcomeClick={(outcome) => onOutcomeClick(mainMarketId, outcome)}
                            />
                            {columnCount === "three_columns" && (
                                <Outcome
                                    outcome={mainOdds.drawOutcome}
                                    marketStatus={mainMarketStatus}
                                    variant="mainMarket"
                                    onOutcomeClick={(outcome) =>
                                        onOutcomeClick(mainMarketId, outcome)
                                    }
                                />
                            )}
                            <Outcome
                                outcome={mainOdds.awayOutcome}
                                marketStatus={mainMarketStatus}
                                variant="mainMarket"
                                onOutcomeClick={(outcome) => onOutcomeClick(mainMarketId, outcome)}
                            />
                        </div>
                    )}
                </div>
            </div>
        </FadeInAnimation>
    );
};

export default Top;
