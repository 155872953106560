import { FC, useContext } from "react";
import { EventStatus } from "@finbackoffice/enums";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import { Svg } from "components/base/svg/Svg";
import Market from "components/pages/sport/base/market/Market";
import HomeTournamentGameScoreboard from "../../scoreboard/HomeTournamentGameScoreboard";
import { HomeTournamentGameItemContext } from "../../HomeTournamentGameItem";
import styles from "./default.module.sass";

const Default: FC = () => {
    const { game, onOutcomeClick, mainMarket } = useContext(HomeTournamentGameItemContext);
    return (
        <FadeInAnimation>
            <div className={styles.homeGameItem}>
                <div className={styles.homeGameItemHeader}>
                    <div>
                        <Svg
                            src={`/common/regions/${game.category_id}.svg`}
                            wrapper="span"
                            className="svg-region-icon"
                            loader
                        />
                        {`${game.category_name} / ${game.tournament_name}`}
                    </div>
                    {game.status === EventStatus.Live && (
                        <span className={styles.liveGameLabel}>Live</span>
                    )}
                </div>
                <HomeTournamentGameScoreboard game={game} />
                <div className={styles.homeGameItemMarket}>
                    {mainMarket && (
                        <Market
                            market={mainMarket}
                            key={mainMarket.id}
                            onOutcomeClick={onOutcomeClick}
                        />
                    )}
                </div>
            </div>
        </FadeInAnimation>
    );
};

export default Default;
